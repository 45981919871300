<template>
  <canvas
    :class="{ '-visible': isVisible, '-hidden': isHidden }"
    ref="canvasElement"
    class="webgl-canvas"
  />
</template>

<script setup lang="ts">
import Webgl from '~/webgl/Webgl';
import type { FloatingPlaneInputData } from '~/webgl/floating-planes/FloatingPlanes';

const route = useRoute();
const nuxtApp = useNuxtApp();

const isVisible = ref<boolean>(false);
const isHidden = ref<boolean>(route.path !== '/');
const canvasElement = ref<null | HTMLCanvasElement>(null);

nuxtApp.provide('Webgl', Webgl());

const { data } = await useAsyncGql({
  operation: 'GetAllPortfolioProjects'
});

watch(
  () => route.path,
  function () {
    isHidden.value = route.path !== '/';
  }
);

const portfolioProjects = computed(function () {
  return data.value.allPortfolioProjects ?? [];
});

const projectsData = computed(function () {
  if (portfolioProjects.value.length) {
    const mapped = portfolioProjects.value.map(function (i) {
      if (!i.teaserImage) {
        return null;
      }

      const aspectRatio = i.teaserImage.width / i.teaserImage.height;
      const baseSizes = {
        width: 600,
        height: 900
      };

      const assetData = {
        width: aspectRatio < 1 ? baseSizes.width : baseSizes.height,
        height: aspectRatio < 1 ? baseSizes.height : baseSizes.width,
        aspectRatio: i.teaserImage.width / i.teaserImage.height,
        type: i.teaserImage.video ? 'video' : 'image'
      };

      const imageUrls = {
        portrait: {
          url: i.teaserImage.urlPortrait,
          urlBlurry: i.teaserImage.urlPortraitBlurry
        },
        landscape: {
          url: i.teaserImage.urlLandscape,
          urlBlurry: i.teaserImage.urlLandscapeBlurry
        }
      };

      const orientation = assetData.aspectRatio < 1 ? 'portrait' : 'landscape';

      const image =
        assetData.type === 'image'
          ? {
              src: String(imageUrls[orientation].url),
              blurrySrc: String(imageUrls[orientation].urlBlurry)
            }
          : null;

      const video =
        assetData.type === 'video'
          ? {
              src: String(i.teaserImage.video?.mp4Med)
            }
          : null;

      return {
        name: String(i.title),
        image,
        video,
        assetData,
        slug: String(i.slug)
      };
    });

    const filtered = mapped.filter((i) => i !== null) as FloatingPlaneInputData[];
    return filtered;
  }
  return [];
});

onMounted(function () {
  if (canvasElement.value) {
    nuxtApp.$Webgl.init(canvasElement.value);

    nuxtApp.$Webgl.webglExperience.value?.floatingPlanesExperience.init(projectsData.value);

    isVisible.value = true;

    setTimeout(function () {
      document.documentElement.style.setProperty('--background-color', '#0d0d0d');
    }, 100);
  }
});
</script>

<style scoped lang="scss">
.webgl-canvas {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: transform 1000ms;

  &.-visible {
    visibility: visible;
  }

  &:not(.-visible) {
    visibility: hidden;
  }

  &.-hidden {
    transform: translateY(-100vh);
  }

  &:not(.-hidden) {
    transform: translateY(0);
  }
}
</style>
